import { useEffect, useMemo, useState } from "react";
import Services from "../../Providers/Services";
import { DashboardLink, RefreshBtn } from "../../Widgets";

// Scss
import "./apiTest.scss";

const ApiTest = () => {
  // =========================  VARIABLES  =============================

  const { getApiTestRes } = Services();
  const [res, setRes] = useState<string>("");
  const data: any = useMemo(
    () => JSON.parse(localStorage.getItem("testConditions") || "{}"),
    []
  );

  // =========================  FUNCTIONS  =============================
  useEffect(() => {
    const apiData = `scan_id=${data.product_scan_id}&limit=${data.no_of_images}&test_case=${data.test_case}&new_certification_id=${data.certification_id}&aws_access_key_id=${process.env.REACT_APP_AWS_ACCESS_KEY_ID}&aws_secret_access_key=${process.env.REACT_APP_AWS_SECRET_ACCESS_KEY}&host=${process.env.REACT_APP_DB_HOST}&user=${process.env.REACT_APP_DB_USER}&password=${process.env.REACT_APP_DB_PASSWORD}&port=${process.env.REACT_APP_DB_PORT}&database=${process.env.REACT_APP_DB_DATABASE}`;
    // lux_meter:${data},
    // certification_id:${data},
    // distance_condition:${data},
    // angle_condition:${data},
    // light_condition:${data},
    // cam_type:${data},
    // phone:${data},
    // env:${data},
    // tester_name:${data},
    // start_date:${data},
    // end_date:${data}

    (async () => {
      console.log(apiData);
      const response = (await getApiTestRes(apiData)) as string;
      setRes(response);
    })();
  }, [data]);

  // ============================  HTML  =============================
  return (
    <section>
      <h1 className="mt-3 text-purple">API Test</h1>
      <div className="d-block text-center content">
        {res ? (
          <>
            <div className="alert alert-success mx-3" role="alert">
              Successfully injected images to the verification API
            </div>
          </>
        ) : (
          <>
            <h2 className="text-purple">
              <span>Processing </span>
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </h2>
            <br />
            <p className="pe-4">
              <i>Please wait...</i>
            </p>
          </>
        )}
      </div>

      {res && (
        <div className="btn-div">
          <RefreshBtn text={"New Test"} />
          <DashboardLink />
        </div>
      )}
    </section>
  );
};

export default ApiTest;
