import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePageContext } from "../../Providers/PageContext";
import { getMainCameraId } from "./GetCamId";

// Scss
import "./scanCam.scss";

const ScanCam = (props: any) => {
  //  =============================  VARIABLES  ===============================

  const { t } = useTranslation(); // Translate
  const { setImg, isIos } = usePageContext();

  const [camId, setCamId] = useState<string>("");
  const [stream, setStream] = useState<MediaStream | null>(null);
  const videoRef = useRef<any>(null);

  const [manualBtn, setManualBtn] = useState<boolean | null>(null); // To control click btn visibility
  const [autoBtn, setAutoBtn] = useState<boolean | null>(null); // To control click btn visibility

  const [autoCapture, setAutoCapture] = useState<boolean>(false); // To capture automatically

  // ==============================  FUNCTIONS  =================================

  // Initial Call
  useEffect(() => {
    window.scrollTo({ top: 0 });
    checkCamPermission();
  }, []);

  const checkCamPermission = async () => {
    await navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then(async (stream: any) => {
        stream.getTracks().forEach((t: any) => t.stop());
        const cameraDetails = await getMainCameraId(isIos, !isIos);
        if (cameraDetails)
          setCamId(cameraDetails.deviceId as unknown as string);
      })
      .catch(async (err: any) => {
        console.log("first call : " + err.name + ": " + err.message);
        if (err.name === "NotReadableError") {
          const cameraDetails = await getMainCameraId(isIos, !isIos);
          if (cameraDetails)
            setCamId(cameraDetails.deviceId as unknown as string);
        } else {
          console.log("denied");
          props.denied();
        }
      });
  };

  useEffect(() => {
    camId && playVideo();
  }, [camId]);

  // play video
  const playVideo = () => {
    const constraints = isIos
      ? {
          audio: false,
          video: {
            deviceId:
              camId && camId !== "unknown" ? { exact: camId } : undefined,
            width: { ideal: 3000 }, // Check and update for the latest models (iPhone 12+)
            facingMode: { exact: "environment" },
            aspectRatio: { exact: 1 },
          },
        }
      : {
          video: {
            deviceId:
              camId && camId !== "unknown" ? { exact: camId } : undefined,
            width: { ideal: 6000 }, // In order to pick the max camera resolution
            facingMode: { exact: "environment" },
            aspectRatio: { exact: 1 },
          },
        };

    window.scrollTo({ top: 0 });
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream: any) => {
        setStream(stream);
      })
      .catch((err) => {
        console.log("after: " + err.name + ": " + err.message);
        props.denied();
      });
  };

  // video Stream
  useEffect(() => {
    if (stream) {
      videoRef.current.srcObject = stream;
      videoRef.current.onloadedmetadata = () => {
        videoRef.current.play();
        setManualBtn(true);
        setAutoBtn(true);
      };
    }
  }, [stream]);

  // Handle btn click
  const handleCapture = (captureType: string) => {
    autoBtn && setAutoBtn(false);
    manualBtn && setManualBtn(false);
    if (captureType === "auto") {
      setAutoCapture(true);
      setManualBtn(null);
    } else {
      setManualBtn(false);
    }
  };

  // For maunal Capture
  useEffect(() => {
    if (manualBtn !== null && manualBtn === false) {
      captureImg();
    }
  }, [manualBtn]);

  // For auto Capture
  useEffect(() => {
    autoCapture && captureImg();
  }, [autoCapture]);

  // Capture Image
  const captureImg = () => {
    const canvas = document.createElement("canvas");
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;
    canvas.getContext("2d")?.drawImage(videoRef.current, 0, 0);
    const imageDataUrl = canvas.toDataURL("image/jpeg");
    setImg(imageDataUrl);
  };

  // Next Capture
  useEffect(() => {
    const match = /uploaded/;
    match.test(props.endText);
    if (match.test(props.endText)) {
      if (autoCapture) {
        if (isIos) {
          setTimeout(() => {
            captureImg();
          }, 1000);
        } else {
          captureImg();
        }
      } else {
        setManualBtn(true);
      }
    } else if (props.endText === "Done") {
      const video = document.getElementById("vid") as HTMLVideoElement;
      video.pause();
      stream?.getTracks().forEach((track: any) => track.stop());
    }
  }, [props.endText]);

  // Download captured image
  // const downloadFile = (img: any) => {
  //   const link = document.createElement("a");
  //   link.download = "capture.jpg";
  //   link.href = img;
  //   link.click();
  //   link.remove();
  // };

  // ===============================  HTML  ================================

  // Side angles
  const angle = () => {
    return (
      <>
        <div className="angle angle-1"></div>
        <div className="angle angle-2"></div>
        <div className="angle angle-3"></div>
        <div className="angle angle-4"></div>
      </>
    );
  };

  // Overlay
  const overlay = () => {
    return (
      <img
        className="position-absolute start-50 top-50 translate-middle overlay"
        style={{ width: "22%" }}
        src={props.overlay}
        alt=""
      />
    );
  };

  // camera text
  const openingCam = () => {
    return (
      <h3 className="w-100 position-absolute top-50 start-50 translate-middle opening-cam-txt">
        <i>{t("openingCam")}</i>
      </h3>
    );
  };

  // Scan Button
  const scanBtn = () => {
    return (
      <div className="btn-div">
        {manualBtn && (
          <button className="click-btn" onClick={() => handleCapture("manual")}>
            {t("scan")}
          </button>
        )}
        {autoBtn && (
          <button
            className="ms-4 click-btn btn-inverse"
            onClick={() => handleCapture("auto")}
          >
            {t("autoScan")}
          </button>
        )}
      </div>
    );
  };

  const subComps = () => {
    return (
      <>
        {angle()}
        {props.productName === "RLC975" && props.overlay && overlay()}
      </>
    );
  };

  // Main
  return (
    <section className="position-relative mt-5">
      <div className="cam-div">
        <video id="vid" ref={videoRef} autoPlay={true} muted playsInline />
        {stream ? subComps() : openingCam()}
      </div>
      <img className="sm-gif" src="assets/images/next_gif/scan.gif" alt="" />
      <h1>{t("scan")}</h1>
      <p className="end-p-text">{props.endText}</p>
      {autoCapture && <p className="end-p-text">{t("autoEnabled")}</p>}
      {scanBtn()}
    </section>
  );
};

export default ScanCam;
