const getDeviceId = () => {
  return localStorage.getItem("deviceId");
};

const setCameraDetails = async (isIos: boolean, isAndroid: boolean) => {
  console.log("setting camera details..");
  try {
    let deviceId: string;
    let cameraDetails;

    if (isIos) {
      cameraDetails = await GetIosCamId();
    } else if (isAndroid) {
      cameraDetails = await GetAndroidCamId();
    }

    if (!cameraDetails?.deviceId) {
      deviceId = "unknown";
    } else {
      deviceId = cameraDetails.deviceId;
    }

    localStorage.setItem("deviceId", deviceId);

    return { deviceId };
  } catch (err: unknown) {
    console.log("camErr", err);
  }
};

const selectMainCamera = async (
  deviceId: string,
  isIos: boolean,
  isAndroid: boolean
) => {
  if (isIos) {
    // Video device list
    const videoDevices = (
      await navigator.mediaDevices?.enumerateDevices()
    )?.filter((device) => device.kind === "videoinput");

    const selectedCamera = videoDevices.find(
      (videoDevice) => videoDevice.deviceId === deviceId
    );
    const isIosMainCamSelected =
      isIos &&
      selectedCamera?.label.toLowerCase().includes("back") &&
      !selectedCamera?.label.toLowerCase().includes("wide");

    if (isIosMainCamSelected) {
      return { deviceId };
    }
  }

  if (isAndroid) {
    const stream = await navigator.mediaDevices.getUserMedia({
      video: {
        deviceId: { ideal: deviceId },
        width: { ideal: 6000 },
      },
    });

    const track = stream.getVideoTracks()[0];
    const capabilities = track.getCapabilities();
    const isAndroidMainCamSelected =
      isAndroid &&
      (capabilities as any)?.focusMode?.includes("continuous") &&
      (capabilities as any)?.facingMode?.includes("environment");

    track.stop();

    if (isAndroidMainCamSelected) {
      return { deviceId };
    }
  }

  window.localStorage.removeItem("deviceId");
  window.localStorage.removeItem("deviceCapabilities");
  return await setCameraDetails(isIos, isAndroid);
};

const GetAndroidCamId = (): Promise<{ deviceId: string } | null> => {
  return new Promise((resolve) => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        devices.forEach(async (device) => {
          if (
            device.kind === "videoinput" &&
            device.label.toLowerCase().includes("back")
          ) {
            const stream = await navigator.mediaDevices.getUserMedia({
              video: {
                deviceId: { ideal: device.deviceId },
                width: { ideal: 6000 },
              },
            });

            const track = stream.getVideoTracks()[0];
            const capabilities = track.getCapabilities();
            if (
              (capabilities as any)?.focusMode?.includes("continuous") &&
              (capabilities as any)?.facingMode?.includes("environment")
            )
              resolve({ deviceId: device.deviceId });

            track.stop();
          }
        });
      })
      .catch((err) => {
        console.log(" get cameraId error : " + err.name + ": " + err.message);
        resolve(null);
      });
  });
};

const GetIosCamId = (): Promise<{ deviceId: string } | null> => {
  return new Promise((resolve) => {
    navigator.mediaDevices
      .enumerateDevices()
      .then((devices) => {
        devices.forEach(async (device) => {
          if (
            device.kind === "videoinput" &&
            device.label.toLowerCase().includes("back") &&
            !device?.label.toLowerCase().includes("wide")
          ) {
            resolve({ deviceId: device.deviceId });
          }
        });
      })
      .catch((err) => {
        console.log(" get cameraId error : " + err.name + ": " + err.message);
        resolve(null);
      });
  });
};

export const getMainCameraId = async (isIos: boolean, isAndroid: boolean) => {
  const deviceId = getDeviceId();
  const hasNoDeviceId = !deviceId;

  if (hasNoDeviceId) {
    return await setCameraDetails(isIos, isAndroid);
  } else {
    return await selectMainCamera(deviceId, isIos, isAndroid);
  }
};
