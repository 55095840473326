// scss
import "./confirmTests.scss";

const ConfirmTests = (finalSubmit: any) => {
  const data: any = JSON.parse(localStorage.getItem("testConditions") || "{}");

  return (
    <section className="confirm-section position-absolute mt-4 top-0 start-0 justify-content-center">
      <form className="bg-white shadow p-3">
        {Object.keys(data).map((key) => (
          <p key={key}>
            {key.replaceAll("_", " ")}: <span>{data[key]}</span>
          </p>
        ))}

        <button
          type="submit"
          onClick={finalSubmit}
          className="click-btn me-3"
          name="update"
        >
          Update
        </button>

        <button
          type="submit"
          onClick={finalSubmit}
          className="click-btn btn-ok"
          name="ok"
        >
          Ok
        </button>
      </form>
    </section>
  );
};

export default ConfirmTests;
