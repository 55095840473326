import { useMemo } from "react";
import API from "../API/API";
import Axios from "./Axios";
import { usePageContext } from "./PageContext";
import { envURL } from "./TestCase";

const Services = () => {
  const { slesctedEnv, setPage, token, setToken, setOopsMsg } =
    usePageContext();

  let headers = {};

  const url: string = useMemo(
    () => envURL[slesctedEnv as keyof typeof envURL].url,
    [slesctedEnv]
  );

  const getURL = () => {
    return url;
  };

  // Error Handler
  const errorHandler = (errMsg: any) => {
    setOopsMsg(errMsg === "ERR_NETWORK" ? 1 : 0);
    setPage(5);
    return null;
  };

  const setHeaders = () => {
    headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
  };
  const getHeaders = () => {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    return headers;
  };

  // Get Token
  const getToken = () => {
    const request = {
      api_key: envURL[slesctedEnv as keyof typeof envURL].key,
    };
    return new Promise((reslove) => {
      Axios.post(url + API.token, request)
        .then((res) => {
          reslove(res.data.access_token);
          setToken(res.data.access_token);
          headers = {
            headers: { Authorization: `Bearer ${res.data.access_token}` },
          };
        })
        .catch((err) => {
          reslove(errorHandler(err.code));
        });
    });
  };

  // fetch All Scan Ids
  const fetchAllScanIds = () => {
    return new Promise((reslove) => {
      Axios.get(url + API.fetchAllScanIds, headers)
        .then((res) => {
          reslove(res.data.data);
        })
        .catch((err) => {
          reslove(errorHandler(err.code));
        });
    });
  };

  // Get Product ID
  const getProductId = (prodName: string) => {
    setHeaders();
    return new Promise((resolve) => {
      Axios.get(url + API.fetchProdId + prodName, headers)
        .then((res) => {
          resolve([res.data.product_config_id, res.data.overlay_file_url]);
        })
        .catch((err) => {
          resolve(errorHandler(err.code));
        });
    });
  };

  // Get Session ID
  const getSessionId = async (geoLoc: any, prodId: number, device: any) => {
    setHeaders();
    const request = {
      geo_location: geoLoc,
      product_config_id: prodId,
      session_metadata: {
        device_details: device,
        certification_data: JSON.parse(
          localStorage.getItem("testConditions") || "{}"
        ),
      },
    };
    return new Promise((resolve) => {
      setHeaders();
      Axios.post(url + API.session, request, headers)
        .then((res) => {
          resolve(res.data.session_id);
        })
        .catch((err) => {
          resolve(errorHandler(err.code));
        });
    });
  };

  // API testing
  const getApiTestRes = (data: string) => {
    return new Promise((resolve) => {
      Axios.get(`http://localhost:8097/verification?${data}`)
        .then((res) => {
          resolve(res.data.Message);
        })
        .catch((err) => {
          resolve(errorHandler(err.code));
        });
    });
  };

  return {
    getToken,
    fetchAllScanIds,
    getApiTestRes,
    getProductId,
    getSessionId,
    getURL,
    getHeaders,
  };
};

export default Services;
