const API = {
  token: "auth/login",
  fetchAllScanIds: "fetch-all-scanids",
  fetchProdId: "fetch-experience?product_scan_id=",
  session: "verify/start-session",
  uploadImg: "verify/upload-image?session_id=",
  decode: "verify/session-result?session_id=",
};

export default API;
