import { useTranslation } from "react-i18next";
import { RefreshBtn } from "../../Widgets";
import { usePageContext } from "../../Providers/PageContext";

// Scss
import "./deniedCam.scss";

const DeniedCam = (props: any) => {
  //  ===========================  VARIABLES  ==============================

  const { t } = useTranslation(); // Translate
  const { isIos } = usePageContext();
  const deniedMsg = props.deniedMsg;
  const gifPath = `assets/images/denied/${props.deniedMsg}_gif.gif`;

  // ===============================  HTML  ================================

  //  Ios Message
  const iosMsg = () => {
    return (
      <ul className="instructions list-unstyled">
        <li className="text-center">{t("instructionIos", { deniedMsg })}</li>
      </ul>
    );
  };

  // Android Message
  const AndroidMsg = () => {
    return (
      <ol className="instructions">
        {deniedMsg === "location" && (
          <>
            <li>{t("instruction_0")}</li>
            <hr />
          </>
        )}
        <li>
          {t("instruction_A")}(<i className="fas fa-lock"></i>)
        </li>
        <li>{t("instruction_B")}</li>
        <li>{t("instruction_C")}</li>
        <li>{t("instruction_D")}</li>
      </ol>
    );
  };

  // Denied Messages and Instructions
  const deniedDiv = () => {
    return (
      <div>
        {/* Denied-message */}
        <h3 className="mb-4">{t("denied", { deniedMsg })}</h3>

        {/* Instrictions */}
        <h3 className="mb-3 text-purple">{t("instructionBtn")}</h3>

        {isIos ? iosMsg() : AndroidMsg()}
      </div>
    );
  };

  return (
    <section className="position-relative">
      {/*  Gif */}
      <img className="w-75 my-3" src={gifPath} alt="gif" />
      {deniedDiv()}
      <div className="btn-div">
        <RefreshBtn text={t("refresh")} />
      </div>
    </section>
  );
};

export default DeniedCam;
