import { useTranslation } from "react-i18next";
import { RefreshBtn } from "../../Widgets";

// Scss
import "./oops.scss";
import { usePageContext } from "../../Providers/PageContext";

const Oops = () => {
  //  =============================  VARIABLES  ============================

  const { t } = useTranslation(); // Translate
  const { oopsMsg } = usePageContext();

  // ===============================  HTML  ================================

  // Main
  return (
    <section className="justify-content-center">
      <h3>{oopsMsg ? t("noInternet") : t("oops")}</h3>
      <div className="btn-div">
        <RefreshBtn text={t("tryAgain")} />
      </div>
    </section>
  );
};

export default Oops;
