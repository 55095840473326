import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePageContext } from "../../Providers/PageContext";

const NativeImg = (props: any) => {
  // ===========================  VARIABLES  =========================
  const { t } = useTranslation(); // Translate
  const { img, setImg } = usePageContext(); // store captured Image
  const [showBtn, setShowBtn] = useState<boolean>(true); // store captured Image

  // ===========================  FUNCTIONS  =========================

  // Next Capture
  useEffect(() => {
    window.scrollTo({ top: 0 });
    if (props.endText.includes("uploaded")) {
      setShowBtn(true);
    }
  }, [props.endText]);

  const readImg = (e: any) => {
    setShowBtn(false);
    if (e.target.files[0]) {
      let fileReader = new FileReader();
      fileReader.readAsDataURL(e.target.files[0]);
      fileReader.onload = () => {
        // props.productName === "harpic_01"
        //   ? resizeImage(fileReader.result)
        //   : sendImg(fileReader.result as string);
        setImg(fileReader.result as string);
      };
    }
  };

  // ==============================  HTML  ============================
  const scanBtn = () => {
    return (
      <div className="btn-div">
        <label htmlFor="imgFile" className="click-btn">
          {t("scan")}
        </label>
        <input
          type="file"
          id="imgFile"
          className="d-none"
          capture="environment"
          accept="image/*"
          onChange={readImg}
        />
      </div>
    );
  };
  // Main
  return (
    <section>
      <div className="cam-div">
        {img && <img className="w-100" src={img} alt="" />}
        {!img && (
          <h3 className="w-100 position-absolute top-50 start-50 translate-middle opening-cam-txt">
            <i>Preview</i>
          </h3>
        )}
      </div>

      <h1>{t("scan")}</h1>
      <p className="end-p-text">
        {img ? props.endText : "Click the scan button to capture"}
      </p>

      {/* Native capture btn */}
      {showBtn && scanBtn()}
    </section>
  );
};

export default NativeImg;
