import { usePageContext } from "../../Providers/PageContext";

// Scss
import "./landing.scss";

const Landing = () => {
  // ============================  VARIABLES  ==============================
  const { setPage } = usePageContext();
  // ==============================  Handles ======================================

  setTimeout(() => {
    setPage(0);
  }, 3500);

  // ===============================  HTML  ================================
  return (
    <section className="intro-section justify-content-evenly">
      <img className="w-75" src="assets/images/intro/text_logo.svg" alt="" />
      <img className="w-50" src="assets/images/intro/intro_gif.gif" alt="" />
      <img
        className="intro-text mb-4"
        src="assets/images/intro/intro_text.png"
        alt=""
      />
    </section>
  );
};

export default Landing;
