const DashboardLink = () => {
  const data: any = JSON.parse(localStorage.getItem("testConditions") || "{}");
  const apiString =
    data.test_type === "API Testing"
      ? "Ii43h4xVz/prod-certification-run-2-api-testing"
      : "CcM_57o4k/prod-certification-run-3-native-webcam";
  return (
    <a
      className="click-btn text-white btn-inverse ms-3 py-3 text-decoration-none"
      target="_blank"
      rel="noreferrer"
      href={`https://grafana-dev.ops.enncrypto.com/d/${apiString}?orgId=1&var-ProductScanID=${data.product_scan_id}&var-CertificationID=${data.certification_id}&var-TestCase=${data.test_case}&var-Tester=${data.tester_name}&var-Phone=${data.phone}&var-Light=${data.light_condition}&var-Angle=${data.angle_condition}&var-Distance=${data.distance_condition}`}
    >
      Dashboard
    </a>
  );
};

// https://grafana-dev.ops.enncrypto.com/d/Ii43h4xVz/prod-certification-run-2-api-testing?orgId=1&var-ProductScanID=holostick_label_01&var-CertificationID=All&var-TestCase=Encrypted&var-Tester=All&var-Phone=All&var-Light=All&var-Angle=All&var-Distance=All&var-ScanStatus=All&var-Result=All

export default DashboardLink;
