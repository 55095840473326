import { useTranslation } from "react-i18next";

const OsSupport = (props: any) => {
  //  ===========================  VARIABLES  ============================

  const { t } = useTranslation(); // Translate

  // ===============================  HTML  ================================
  
  // Main
  return (
    <section className="justify-content-center">
      {props.msg === 0 ? (
        <h3>{t("osSufficient")}</h3>
      ) : props.msg === 1 ? (
        <h3>{t("osSafari")}</h3>
      ) : (
        <h3>{t("osChrome")}</h3>
      )}
    </section>
  );
};

export default OsSupport;
