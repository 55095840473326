import { useState, createContext, useContext } from "react";

const PageContext = createContext<any>(null);

export const PageProvider = ({ children }: { children: any }) => {
  const [page, setPage] = useState<number>(-1); // select page
  const [slesctedEnv, setSelectedEnv] = useState<string>("Qa"); // selected Env
  const [token, setToken] = useState<string>(""); // Store token
  const [img, setImg] = useState<any>(null); // store captured Image
  const [isIos, setIsIos] = useState<boolean>(false); // To change Messages and cam constraints
  const [oopsMsg, setOopsMsg] = useState<boolean>(false); // To change oops Messages

  const values = {
    page,
    setPage,
    slesctedEnv,
    setSelectedEnv,
    token,
    setToken,
    img,
    setImg,
    isIos,
    setIsIos,
    oopsMsg,
    setOopsMsg,
  };
  return <PageContext.Provider value={values}>{children}</PageContext.Provider>;
};

export const usePageContext = () => {
  return { ...useContext(PageContext) };
};

export default PageContext;
