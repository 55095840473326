const preSelections = (label: string, list: string[], changeEvent: any) => {
  return (
    <div key={label}>
      <label htmlFor={label}>{label.replaceAll("_", " ")}</label>
      <select
        name={label}
        id={label}
        className="form-control"
        onChange={changeEvent}
      >
        {list.map((el) => (
          <option value={el} key={el}>
            {el}
          </option>
        ))}
      </select>
    </div>
  );
};

const selections = (label: string, list: string[]) => {
  return (
    <div key={label}>
      <label htmlFor={label}>{label.replaceAll("_", " ")}</label>
      <select name={label} id={label} className="form-control">
        {list.map((el) => (
          <option value={el} key={el}>
            {el}
          </option>
        ))}
      </select>
    </div>
  );
};

const inupts = (name: string) => {
  return (
    <>
      <label htmlFor={name}>{name}</label>
      <input id={name} name={name} className="form-control" type="number" />
    </>
  );
};

export { preSelections, selections, inupts };
