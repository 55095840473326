import { DashboardLink, RefreshBtn } from "../../Widgets";

// Scss
import "./testResult.scss";

const TestResult = (props: any) => {
  // =======================  HTML  ===========================

  // upload time for each session
  const uploadTimes = () => {
    return (
      <table className="table border rounded">
        <thead>
          <tr>
            <th>Session ID</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {props.uploadTime.map((e: any, index: number) => (
            <tr key={index}>
              <td>{e.session}</td>
              <td>
                <span>{e.time / 1000}</span> s
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  // Main
  return (
    <section>
      <h2 className="my-4">Test Completed</h2>
      {uploadTimes()}
      <p>
        Start: <span>{props.startTime}</span>
      </p>
      <p>
        End: <span>{props.endTime}</span>
      </p>

      <div>
        <div>
          <RefreshBtn text={"New Test"} />
          <DashboardLink />
        </div>
      </div>
    </section>
  );
};

export default TestResult;
