const testTypes = ["Manual Testing", "API Testing"];

const env = ["Qa", "Dev", "Delivery QA"];

const envURL = {
  Dev: {
    key: "B6Vge8Z3jZLBQSDdEjFXhiv6XheXCm15o",
    url: "https://verification-dev1.pre.enncrypto.com/",
  },
  Qa: {
    key: "B6Vge8Z3jZLBQSDdEjFXhiv6XheXCm15o",
    url: "https://qa-verification1.pre.enncrypto.com/",
  },
  "Delivery QA": {
    key: "sscMu3N9R19h8dN9wvSfDFdbn8Dfr2KnyL5H55TUcis",
    url: "https://stage-verification-api.pre.enncrypto.com/",
  }
};

export { testTypes, env, envURL }
